import React from 'react'
import {
  ContentHeader,
  Paragraph,
  SectionHeader,
  UnsortedList,
  StyledImage,
} from './styles'
import { OrangeButtonAsLink, colorScheme } from '../../styles'
import OrangeDSIcon from 'src/components/UI/MarkdownIcon/OrangeDsIcon'
import useWidth from 'src/hooks/window/useWidth'
import { WIDTH_MD, WIDTH_XXL } from 'src/styles/breakpoints'

import data from './data.json'
import useDataLayer from 'src/hooks/useDataLayer/dataLayerBody'

const VoceDesenvolveSuaEmpresaLucra = () => {
  const width = useWidth()
  const [ sendDatalayerEvent ] = useDataLayer()

  type dataType = {
    icon: string;
    text: string;
  }

  return (
    <ContentHeader
      id='voce-desenvolve-sua-empresa-lucra'
    >
      <div className='container'>
        <div className='row'>
          {width >= WIDTH_MD
            ? <div className='divImage col-md-6 col-lg-7 col-xl-6'>
              <StyledImage
                src='https://central-imagens.bancointer.com.br/images-without-small-versions/api-pix-dobra5-web/image.webp'
                alt='Homem segurando seu cartão Inter Empresas, enquanto toma café.'
              />
            </div>
            : ''
          }

          <div className='col-12 col-md-6 col-lg-5 col-xl-6'>
            {width < WIDTH_MD
              ? <img
                  src='https://central-imagens.bancointer.com.br/images-without-small-versions/api-pix-dobra5-360/image.webp'
                  alt='Homem segurando seu cartão Inter Empresas, enquanto toma café.'
                />
              : ''
            }
            <SectionHeader>Você desenvolve, sua empresa lucra.</SectionHeader>
            {width >= WIDTH_XXL ? <Paragraph>O Inter Empresas é parceiro de verdade e oferece:</Paragraph> : ''}
            <UnsortedList>
              {data.map((item: dataType) => (
                <li
                  key={item.icon}
                >
                  <div>
                    <OrangeDSIcon
                      className='icon'
                      icon={item.icon}
                      size='MD'
                      color={colorScheme.darkOrange}
                    />
                  </div>
                  <p>
                    {item.text}
                  </p>
                </li>
              ))}
            </UnsortedList>
            <OrangeButtonAsLink
              rel='noreferrer'
              target='_blank'
              href='https://developers.inter.co/references/pix'
              onClick={() => {
                sendDatalayerEvent({
                  section: 'hero',
                  element_action: 'click button',
                  section_name: 'Você desenvolve, sua empresa lucra',
                  element_name: 'Acesse agora',
                  redirect_url: 'https://developers.inter.co/references/pix',
                })
              }}
            >
              Acesse agora

            </OrangeButtonAsLink>
          </div>
        </div>
      </div>
    </ContentHeader>
  )
}

export default VoceDesenvolveSuaEmpresaLucra
