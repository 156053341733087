import { grayscale } from 'src/styles/colors'
import { Card, colorScheme } from '../../styles'
import { breakpoints } from 'src/styles/breakpoints'
import styled from 'styled-components'

export const ContentHeader = styled.section`
  font-family: Citrina, sans-serif;
  background-color: white;
  padding: 32px 0 48px 0;

  
  @media (min-width: ${breakpoints.md}){
    padding: 48px 0;
  }
  
  @media (min-width: ${breakpoints.xxl}){
    padding: 96px 0;
  }

  .divCards{
    @media (min-width: ${breakpoints.md}){
      display: flex;
      flex-direction: column;
      margin: 32px 0;
    }
    @media (min-width: 1024px){
      justify-content: flex-end;
      flex-direction: row;
    }
    
    @media (min-width: ${breakpoints.xxl}){
      display: flex;
      padding: 0;
      max-width: 817px;
    }
  }
`

export const DivPrincipal = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  
  @media (min-width: ${breakpoints.xxl}) {
      max-width: 454px;
      align-content: flex-start;
  }

  .orangeButton{
    @media (min-width: ${breakpoints.md}) {
      width: 400px;
    }
    @media (min-width: ${breakpoints.xxl}) {
      width: 100%;
    }
  }
`

export const SectionTitle = styled.h2`
  font-family: Citrina, sans-serif;
  font-weight: 500;
  font-size: 28px;
  text-align: center;
  line-height: 33.6px;
  color: ${grayscale[500]};

  @media (min-width: ${breakpoints.md}){
    margin: 0;
  }

  @media (min-width: 1024px){
    font-size: 40px;
    line-height: 44px;
  }

  @media (min-width: ${breakpoints.md}){
    font-size: 48px;
    line-height: 52.8px;
    text-align: left;
    margin-bottom: 24px;
  }
`

export const SandCard = styled(Card)`
  &>h2,h3{
    color: ${colorScheme.darkOrange}
  }
  p{
    color: ${grayscale[400]};
  }
  margin: 24px 0;
  background-color: ${colorScheme.sand};  

  @media (min-width: ${breakpoints.md}){
    padding: 24px;
    margin: 8px 0;
  }

  @media (min-width: 1024px){
    /* width: 456px; */
    margin: 0 12px;
    width: 80%;
    p{
      margin: 0;
    }
  }

  @media (min-width: ${breakpoints.xxl}){
    /* width: 264px; */
  }

  @media (min-width: ${breakpoints.xxxl}){
    height: 217px;
    /* width: 48.42%; */
    margin: 0;
  }
`
