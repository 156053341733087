import { breakpoints } from './../../../../../styles/breakpoints'
import styled from 'styled-components'
import { colorScheme } from '../../styles'

export const ContentHeader = styled.section`
  background-color: ${colorScheme.darkOrange};
  padding: 48px 0;

  @media (min-width: 1024px){
    padding: 63px 0;
  }

  @media (min-width: ${breakpoints.xxl}){
    padding: 85.75px 0;
  }

  .divPrincipal{
    display: flex;
    flex-direction: column;
    justify-content: center;

    @media (min-width: ${breakpoints.md}){
      padding-right: 32px;
    }
  }

  .rowDiv{
    justify-content: space-between;
  }

  .divPixCount{
    @media (min-width: 1024px ) {
      display: flex;
      justify-content: flex-end;
    }
  }
`
export const NumberOfPix = styled.div`
  background-image: url(https://central-imagens.bancointer.com.br/images-without-small-versions/api-pix-grafismo/image.webp);
  background-size: cover;
  height: 160px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 24px 0;

  @media (min-width: ${breakpoints.md}){
    margin: 0;
  }

  @media (min-width: 1024px){
      width: 435px;
      height: 218px;
    }

  @media (min-width: ${breakpoints.xxl}){
    width: 495px;
    height: 247.5px;
  }

  p{
    font-family: Citrina, sans-serif;
    font-weight: 500;
    font-size: 48px;
    line-height: 52.8px;
    color: white;
    text-align: center;
    margin: 0;

    @media (min-width: ${breakpoints.md}){
      font-size: 56px;
      line-height: 61.6px;
    }

    @media (min-width: 1024px){
      letter-spacing: -2%;
      line-height: 79.2px;
      font-size: 72px ;
      font-weight: 700;
    }

    @media (min-width: ${breakpoints.xxl}){
      font-size: 80px;
      line-height: 88px;
    }
  }
`

export const SectionTitle = styled.h2`
  color: white;
  font-family: Citrina, sans-serif;
  font-weight: 500;
  font-size: 24px;
  line-height: 28.8px ;

  @media (min-width: ${breakpoints.md}) {
    margin-bottom: 16px;
  }

  @media (min-width: 1024px){
    font-size: 28px;
    line-height: 33.6px;
  }

  @media (min-width: ${breakpoints.xxl}){
    font-size: 48px;
    line-height: 52.8px;
  }
`

export const Paragraph = styled.p`
  color: white;
  font-family: Inter, sans-serif;
  font-weight: 400;
  font-size: 16px;
  line-height: 19.36px;

  @media (min-width: ${breakpoints.md}) {
    margin-bottom: 0;
  }

  @media (min-width: 1024px){
    font-size: 18px;
    line-height: 21.78ox;
  }

`
