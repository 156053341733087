import React from 'react'
import { ContentHeader, NotaACard } from './styles'

const NotaANoBancoCentral = () => {
  return (
    <ContentHeader
      aria-label='Homem com as mãos entrelaçadas proxima ao rosto enquanto sorri olhando para o computador'
      id='nota-a-no-banco-central'
    >
      <div className='container'>
        <div className='row'>
          <div className='col-12 col-md-8 col-lg-6 col-xl-5'>
            <NotaACard>
              <h2>
                Nota A
                <span>
                  no Banco Central do Brasil
                </span>
              </h2>
              <p>
                As APIs do Inter fazem parte de um ecossistema completo e estruturado. Desde a versão demo até a mais recente, todas as atualizações são feitas pensadas estrategicamente para promover o crescimento do seu negócio.
              </p>
            </NotaACard>
          </div>
        </div>
      </div>
    </ContentHeader>
  )
}

export default NotaANoBancoCentral
