import React from 'react'
import { ContentHeader, SectionTitle, StyledParagrafer, TitleSpan } from './styles'
import { OrangeButtonAsLink, WhiteTransparentButton } from '../../styles'

import ScrollTo from 'src/components/ScrollTo'
import useUtms from 'src/hooks/useUtms'
import useDataLayer from 'src/hooks/useDataLayer/dataLayerBody'

const HeroAgilidadeParaReceberDinheiro = () => {
  const utmLink = useUtms({ link: `${process.env.OPEN_ACCOUNT_PJ}`, campanha: '' })
  const [ sendDatalayerEvent ] = useDataLayer()
  return (
    <ContentHeader
      id='hero'
      aria-label='Mulher sorridente olhando para tela do seu notebook enquanto segura seu smartphone.'
    >
      <div className='container'>
        <div className='row'>
          <div className='col-12 col-md-6 col-lg-5'>
            <SectionTitle>
              <TitleSpan>
                API Pix Inter Empresas
              </TitleSpan>
              Agilidade para receber dinheiro e economizar tempo
            </SectionTitle>
            <StyledParagrafer>Seja qual for o seu negócio, conte com um sistema completo para automatização das suas transações via Pix.</StyledParagrafer>
            <OrangeButtonAsLink
              href={utmLink}
              target='_blank'
              rel='noreferrer'
              onClick={() => {
                sendDatalayerEvent({
                  section: 'hero',
                  element_action: 'click button',
                  section_name: 'API Pix Inter Empresas',
                  element_name: 'Abrir conta PJ',
                  redirect_url: utmLink,
                })
              }}
            >
              Abrir conta PJ
            </OrangeButtonAsLink>

            <ScrollTo
              to='#o-que-e-api-pix'
              section='hero'
              sectionName='API Pix Inter Empresas'
              elementName='Saber mais'
            >
              <WhiteTransparentButton>
                Saber mais
              </WhiteTransparentButton>
            </ScrollTo>
          </div>
        </div>
      </div>
    </ContentHeader>
  )
}

export default HeroAgilidadeParaReceberDinheiro
