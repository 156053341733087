import React from 'react'
import * as S from './styles'
import KnowMoreButton from './components/KnowMoreButton/_index'
import useWidth from 'src/hooks/window/useWidth'
import { WIDTH_LG } from 'src/utils/breakpoints'

const OQueEApiPix = () => {
  const width = useWidth()

  return (
    <S.ContentHeader
      id='o-que-e-api-pix'
    >
      <div className='container'>
        <div className='row'>
          <div className='col-12 col-md-6 col-xl-5'>
            <S.BrownCard aria-label='Tela de um notebook acessando o Internet banking Inter Empresas.'>
              <h2>O que é a API Pix?</h2>
              <p>Uma solução que se integra aos outros sistemas operacionais da sua empresa. Com a API Pix do Inter Empresas, você pode garantir um fluxo de caixa maior, além de poupar muito tempo na gestão financeira do seu negócio.</p>
              {width <= WIDTH_LG ? <KnowMoreButton /> : ''}
            </S.BrownCard>
          </div>
          <div className='col-12 col-md-6 col-xl-7 divWhiteCards'>
            <S.WhiteCard>
              <h3>Pix Cob</h3>
              <p>Utilizando o QR Code, o Pix Cob possibilita a realização de cobranças imediatas e a configuração de um prazo de expiração para o código. Essa funcionalidade é ideal para vendas em lojas físicas, comércio eletrônico e app de delivery, por exemplo.</p>
              {width >= WIDTH_LG ? <KnowMoreButton /> : ''}

            </S.WhiteCard>
            <S.WhiteCard>
              <h3>Pix CobV</h3>
              <p className='breakInXxl'>Gere cobranças com data de vencimento, com a possibilidade de estabelecer juros e multas para pagamentos atrasados e descontos para pagamentos antecipados. A melhor opção para negócios que trabalham com pagamentos em datas futuras, como academias, clínicas, estacionamentos etc.</p>
              {width >= WIDTH_LG ? <KnowMoreButton /> : ''}
            </S.WhiteCard>
          </div>
        </div>
      </div>
    </S.ContentHeader>
  )
}

export default OQueEApiPix
