import React from 'react'
import { ContentHeader, SectionTitle, StyledH3Li, StyledParagrafer, StyledUl, TextsDiv } from './styles'
import { OrangeButton, WhiteButton, colorScheme } from '../../styles'
import OrangeDsIcon from 'src/components/UI/MarkdownIcon/OrangeDsIcon'
import { white } from 'src/styles/colors'
import data from './data.json'
import { VantagensProps, dataType } from './types'
import useWidth from 'src/hooks/window/useWidth'
import { WIDTH_MD } from 'src/styles/breakpoints'

const VantagensDeReceberAtravesDaApi = ({ setModalOpen }: VantagensProps) => {
  const width = useWidth()
  return (
    <ContentHeader
      id='vantagens-de-receber-atraves-da-api'
      aria-label='Homem sorrindo enquanto utiliza seu smartphone.'
    >
      <div className='container'>
        <div className='row'>
          <div className='divPrincipal col-12 col-md-7 col-lg-6 col-xl-5'>
            <SectionTitle>
              Vantagens de receber através do API Pix
            </SectionTitle>
            <StyledUl>
              {data.map((item: dataType) => (
                <li
                  key={item.icon}
                >
                  <div>
                    <OrangeDsIcon
                      className='icon'
                      icon={item.icon}
                      size='MD'
                      color={width < WIDTH_MD ? white : colorScheme.darkOrange}
                    />
                  </div>
                  <TextsDiv>

                    <StyledH3Li>
                      {item.title}
                    </StyledH3Li>
                    <StyledParagrafer>
                      {item.text}
                    </StyledParagrafer>
                  </TextsDiv>
                </li>
              ))}
            </StyledUl>
            {width < WIDTH_MD
              ? <WhiteButton onClick={() => setModalOpen(true)}>Falar com um especialista</WhiteButton>
              : <OrangeButton onClick={() => setModalOpen(true)}>Falar com um especialista</OrangeButton>
            }
          </div>
        </div>
      </div>
    </ContentHeader>
  )
}

export default VantagensDeReceberAtravesDaApi
