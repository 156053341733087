import styled from 'styled-components'
import { colorScheme } from '../../styles'
import { grayscale } from 'src/styles/colors'
import { breakpoints } from 'src/styles/breakpoints'

export const ContentHeader = styled.section`
  padding: 48px 0;
  .divImage{
    display: flex;
    justify-content: center;
    flex-direction: column;
  }

  @media (min-width: ${breakpoints.xxl}){
    padding: 96px 0;
  }
`

export const SectionHeader = styled.h2`
  font-family: Citrina, sans-serif;
  font-weight: 500;
  font-size: 24px;
  line-height: 28.8px;
  color: ${grayscale[500]};
  margin: 24px 0;

  @media (min-width: ${breakpoints.md}){
    font-size: 28px;
  }

  @media (min-width: ${breakpoints.xxl}){
    font-size: 48px;
    line-height: 52.8px;
  }
`

export const Paragraph = styled.p`
  font-size: 18px;
  font-weight: 600;
  font-family: Inter, sans-serif;
  color: ${grayscale[600]};
  line-height: 21.78px;
`

export const UnsortedList = styled.ul`
  list-style: none;

  @media (min-width: 1024px) {
    margin: 24px 0;
  }

  li{
    display: flex;
    margin-bottom: 16px ;
    .icon{
      margin-right: 8px;
    }
    p{
      font-family: Inter, sans-serif;
      font-weight: 400;
      font-size: 16px;
      line-height: 19.36px;
      color: ${grayscale[600]};
      margin: auto 0;
    }
  }
`

export const StyledImage = styled.img`
  width: 100%;

  @media(min-width: 1024px){
    width: 476px;
  }
  @media (min-width: ${breakpoints.xxl}){
    width: 507px;
  }
`
