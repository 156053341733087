import React, { useEffect, useState } from 'react'
import Layout from 'src/layouts/BaseLayout'
import { Wrapper } from './styles'
import PageContext from './pageContext.json'
import useDomReady from 'src/hooks/window/useDomReady'
import { Modal } from 'src/components/Modal'
import useUtms from 'src/hooks/useUtms'
import { initTheme, Theme } from '@interco/inter-ui'

import HeroAgilidadeParaReceberDinheiro from './sections/HERO-agilidade-para-receber-dinheiro/_index'
import OQueEApiPix from './sections/o-que-e-api-pix/_index'
import ComoFuncionaAApiPix from './sections/como-funciona-a-api-pix/_index'
import VantagensDeReceberAtravesDaApi from './sections/vantagens-de-receber-atraves-da-api/_index'
import VoceDesenvolveSuaEmpresaLucra from './sections/voce-desenvolve-sua-empresa-lucra/_index'
import NotaANoBancoCentral from './sections/nota-a-no-banco-central/_index'
import ComDinheiroNaoSeBrinca from './sections/com-dinheiro-nao-se-brinca/_index'
import NewContactForm from './components/ContactForm/_newContactForm'

const ApiPix = () => {
  const [ isModalOpen, setIsModalOpen ] = useState(false)
  const domReady = useDomReady()

  const utmLink = useUtms({ link: `${process.env.OPEN_ACCOUNT_PJ}`, campanha: '' })

  const openAccountPJFormModal = () => {
    window.location.href = utmLink
  }

  useEffect(() => {
    initTheme(Theme.PF)
  }, [])

  const openModal = domReady && (
    <Modal
      isModalOpen={isModalOpen}
      setIsModalOpen={setIsModalOpen}
      locationToRender={document.body}
    >
      <NewContactForm
        closeModal={() => setIsModalOpen(false)}
      />

    </Modal>
  )
  return (
    <Wrapper>
      {openModal}
      <Layout
        handleHeaderOpenAccount={() => openAccountPJFormModal()}
        pageContext={PageContext}
      >
        <HeroAgilidadeParaReceberDinheiro />
        <OQueEApiPix />
        <ComoFuncionaAApiPix setModalOpen={setIsModalOpen} />
        <VantagensDeReceberAtravesDaApi setModalOpen={setIsModalOpen} />
        <VoceDesenvolveSuaEmpresaLucra />
        <NotaANoBancoCentral />
        <ComDinheiroNaoSeBrinca />

      </Layout>
    </Wrapper>
  )
}

export default ApiPix
