import { breakpoints } from './../../../../../styles/breakpoints'
import styled from 'styled-components'
import { colorScheme } from '../../styles'

export const ContentHeader = styled.section`
  color: white;
  display: flex;
  flex-direction: column;
  background-color: ${colorScheme.darkOrange};
  padding: 24px 0;
  background-repeat: no-repeat;
  background-position-x: right;
  background-position-y: top;
  
  h1, span, p{
    color: white;
  }

  @media (min-width: ${breakpoints.md}){
    background-image: url(https://central-imagens.bancointer.com.br/images-without-small-versions/api-pix-hero-image/image.webp);
    background-size: 382px;
    padding: 48px 0;
  }
  @media (min-width: 1024px){
    background-image: url(https://central-imagens.bancointer.com.br/images-without-small-versions/pix-api-1440-dobra1/image.webp);
    background-size: 576px;
  }
  @media (min-width: ${breakpoints.xxl}){
    background-image: url(https://central-imagens.bancointer.com.br/images-without-small-versions/pix-api-1440-dobra1/image.webp);
    padding: 94px 0 68px 0;
    background-size: 727px;
  }
`

export const SectionTitle = styled.h1`
  font-size: 24px;
  font-family: Citrina;

  @media (min-width: 768px) {
    font-size: 28px;
    line-height: 33.6px;
  }
  
  @media (min-width: 1024px) {
    font-size: 40px;
    font-weight: 500;
    line-height: 44px;
    margin-bottom: 24px;
  }

  @media(min-width: ${breakpoints.xxl}){
    font-size: 56px;
    line-height: 61.6px;
  }
`

export const TitleSpan = styled.span`
  font-size: 20px;
  margin-bottom: 16px;
  display: flex;
  font-family: Citrina;

  @media (min-width: 1024px) {
    font-size: 28px ;
    font-weight: 500;
    line-height: 33.6px;
    margin-bottom: 24px;
  }
`

export const StyledParagrafer = styled.p`
    font-size: 16px;
    font-family: Inter;
    line-height: 19.36px;
    margin-bottom: 32px;

    @media (min-width: 1024px) {
      font-weight: 400;
      font-size: 18px;
      line-height: 21.78px;
      margin-bottom: 24px;
    }
`
