import React from 'react'
import OrangeDsIcon from 'src/components/UI/MarkdownIcon/OrangeDsIcon'
import { orange } from 'src/styles/colors'
import { KnowMoreDiv } from './styles'
import ScrollTo from 'src/components/ScrollTo'

type ButtonProps = {
  styles?: string;
}

const KnowMoreButton = ({ styles }: ButtonProps) => {
  return (
    <ScrollTo
      to='#como-funciona-a-api-pix'
      section='dobra_02'
      sectionName='O que é a API Pix'
      elementName='Saber mais'
      styles={styles}
    >
      <KnowMoreDiv>
        Saber mais
        <div className='divIcon'>
          <OrangeDsIcon
            icon='arrow-right'
            size='MD'
            color={orange.extra}
          />
        </div>
      </KnowMoreDiv>
    </ScrollTo>
  )
}

export default KnowMoreButton
