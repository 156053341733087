import { orange } from 'src/styles/colors'
import styled from 'styled-components'

export const KnowMoreDiv = styled.div`
  display: flex;
  justify-content: flex-end;
  margin: 0;
  color: ${orange.extra};
  font-family: Inter, sans-serif;
  font-weight: 700;
  font-size: 18px;
  line-height: 21.78px;
  cursor: pointer;

  .divIcon{
    margin-left: 4px;
  }
`
