import { breakpoints } from 'src/styles/breakpoints'
import { Card, colorScheme } from '../../styles'
import styled from 'styled-components'
export const ContentHeader = styled.section`

  h1,h2,h3,h4,h5{
    font-family: Citrina, sans-serif;
  }
  p{
    font-family: Inter;
  }

  padding: 24px 0;
  background-color: ${colorScheme.sand};

  @media (min-width: ${breakpoints.md}) {
    padding: 56px 0;
  }

  @media (min-width: ${breakpoints.xxl}) {
    padding: 96px 0;
  }

  .divWhiteCards{
    @media (min-width: ${breakpoints.xxl}) {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      margin: 24px 0;
    };
  }
`
export const BrownCard = styled(Card)`
  &>h2,h3,p{
    color: white;
  }
  background-image: url(https://central-imagens.bancointer.com.br/images-without-small-versions/pix-api-dobra2-360/image.webp);
  height: 702px;
  background-size: cover;
  background-position: center;

  @media( min-width: ${breakpoints.md}){
    background-image: url(https://central-imagens.bancointer.com.br/images-without-small-versions/pix-api-dobra2-768/image.webp);
    height: 770px;
    padding: 48px;
    &>h2,h3,p{
      color: white; 
      margin-bottom: 16px;
    }
  }

  @media (min-width: 1024px){
    background-image: url(https://central-imagens.bancointer.com.br/images-without-small-versions/api-pix-dobra2-1024/image.webp);
    height: 652px;
  }

  @media (min-width: ${breakpoints.xxl}){
    background-image: url(https://central-imagens.bancointer.com.br/images-without-small-versions/api-pix-dobra2-1440/image.webp);
    height: 520px;
  }

  @media (min-width: ${breakpoints.xxll}){
    background-image: url(https://central-imagens.bancointer.com.br/images-without-small-versions/api-pix-note/image.webp);
  }
`

export const WhiteCard = styled(Card)`
  background-color: white;
  
  &>h2,h3{
    color: ${colorScheme.darkOrange};
    margin-bottom: 16px;
  }
  
  p{
    color: ${colorScheme.darkGray};
  }

  @media ( min-width: ${breakpoints.xxl}){
    width: 100%;
    margin: 0;
  }

  .breakInXxl{
    @media (min-width: ${breakpoints.xxl}) {
      width: 552px;
    }
  }
`
