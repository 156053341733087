import { breakpoints } from 'src/styles/breakpoints'
import { orange } from 'src/styles/colors'
import styled from 'styled-components'

export const Wrapper = styled.div`
`

type ButtonProps = {
  $width?: string;
}

export const colorScheme = {
  darkOrange: '#72370E',
  claryOrange: '#943D15',
  sand: '#FDF8EE',
  darkGray: '#161616',
}

const styledButton = styled.div<{$width?: string}>`
  padding: 15px;
  border-radius: 8px;
  width: ${(props: ButtonProps) => props.$width || '100%'} ;
  font-size: 14px;
  font-family: Sora;
  font-weight: 700;
  display: flex;
  justify-content: center;
  align-items: center;
  
  &:focus{
    border: none !important;
    outline: none !important;
  }
  
  a{
    text-decoration: none;
    font-size: 14px;
    font-family: Sora;
    font-weight: 700;
    margin: 0;
    padding: 0;
  }
`

export const WhiteTransparentButton = styled(styledButton)`
  outline: 1px solid white;
  margin-top: 16px;
  background-color: transparent;
  transition: color 0.2s linear, outline 0.2s linear;
  a{
    color: white;
    &:hover{
      color: ${orange.extra};
      outline: 1px solid ${orange.extra};
    }
  }
  color: white;
  &:hover{
    color: ${orange.extra};
    outline: 1px solid ${orange.extra};
  }
`

export const OrangeButton = styled(styledButton)`
  background-color: ${orange.extra};
  transition: all ;
  transition: color 0.2s linear;
  
  a{
    color: white;
    &:hover{
      color: ${colorScheme.darkOrange}
    }
  }
  color: white;
  &:hover{
    color: ${colorScheme.darkOrange}
  }
`

export const OrangeButtonAsLink = styled.a<{$width?: string}>`
  background-color: ${orange.extra};
  transition: all ;
  transition: color 0.2s linear;
  text-decoration: none;
  color: white;
  padding: 15px;
  border-radius: 8px;
  width: ${(props: ButtonProps) => props.$width || '100%'} ;
  font-size: 14px;
  font-family: Sora;
  font-weight: 700;
  display: flex;
  justify-content: center;
  align-items: center;

  &:hover{
    color: ${colorScheme.darkOrange}
  }

  &:focus{
    border: none !important;
    outline: none !important;
  }
`

export const WhiteButton = styled(styledButton)`
  background-color: white;
  transition: color 0.2s linear;
  a{
    color: ${orange.extra};
    &:hover{
    color: ${colorScheme.darkOrange}
  }
  }
  color: ${orange.extra};
  &:hover{
    color: ${colorScheme.darkOrange}
  }
`

export const Card = styled.div`
  &>h2,h3{
    font-size: 24px;
    font-family: Citrina, sans-serif;
    font-weight: 500;
    line-height: 28.8px;
  }
  &>p{
    font-size: 18px;
    font-family: Inter, sans-serif;
    font-weight: 400;
    line-height: 21.78px;
    margin: 16px 0;
    letter-spacing: 0;
  }
  border-radius: 16px;
  padding: 24px;
  margin: 24px 0;


  @media (min-width: ${breakpoints.md}){
    padding: 32px 48px;
  }
`

export const CloseButton = styled.button`
  @media (max-width: ${breakpoints.sm}) {
    right: 10px;
  }

  position: absolute;
  right: 25px;
  top: 0;
  z-index: 10;
  outline: none !important;

  &:focus{
    outline: none;
  }
`
