import React from 'react'
import { ContentHeader, Paragraph, SectionTitle } from './styles'
import PixCount from './components/PixCount/_index'
import { WIDTH_MD } from 'src/styles/breakpoints'
import useWidth from 'src/hooks/window/useWidth'

const ComDinheiroNaoSeBrinca = () => {
  const width = useWidth()

  return (
    <ContentHeader id='com-dinheiro-nao-se-brinca'>
      <div className='container'>
        <div className='row rowDiv'>
          <div className='divPrincipal col-12 col-md-6 col-lg-5'>
            <SectionTitle>
              Com dinheiro, não se brinca.
              E o Inter Empresas sabe disso.
            </SectionTitle>
            {width < WIDTH_MD
              ? (<PixCount />)
              : ''}
            <Paragraph>
              Essa é a quantidade de transações via Pix realizadas pelo Inter em 2023. A sua empresa não pode ficar de fora.
            </Paragraph>
          </div>
          {width >= WIDTH_MD
            ? (<div className='col-md-6 col-lg-7 divPixCount'><PixCount /></div>)
            : ''}
        </div>
      </div>
    </ContentHeader>
  )
}

export default ComDinheiroNaoSeBrinca
