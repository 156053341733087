import React from 'react'
import { NumberOfPix } from '../../styles'

const PixCount = () => {
  return (
    <NumberOfPix>
      <p>
        2.839.894.012
      </p>
    </NumberOfPix>
  )
}

export default PixCount
