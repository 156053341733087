import React from 'react'
import { ContentHeader, DivPrincipal, SandCard, SectionTitle } from './styles'
import { OrangeButton } from '../../styles'
import useWidth from 'src/hooks/window/useWidth'
import { WIDTH_MD, WIDTH_XL } from 'src/styles/breakpoints'
import useDataLayer from 'src/hooks/useDataLayer/dataLayerBody'

type ComoFuncionaProps = {
  setModalOpen: Function;
}

const ComoFuncionaAApiPix = ({ setModalOpen }: ComoFuncionaProps) => {
  const width = useWidth()
  const [ sendDatalayerEvent ] = useDataLayer()

  return (
    <ContentHeader id='como-funciona-a-api-pix'>
      <div className='container'>
        <div className='row d-flex justify-content-between'>
          <DivPrincipal className='col-12 col-xl-5'>
            <SectionTitle>
              Como Funciona a API Pix Inter Empresas?
            </SectionTitle>
            { width < WIDTH_XL
            ? (
              <div className='divCards'>
                <SandCard>
                  <h3>Pix Cob e CobV</h3>
                  <p>Gere cobranças imediatas e com data de vencimento, juros, multas e até descontos.</p>
                </SandCard>
                {/* <SandCard>
                  <h3>Sandbox e SDKs</h3>
                  <p>Ambiente de teste do Inter Empresas. SDKs nas principais linguagens de programação para auxiliar seu desenvolvimento.</p>
                </SandCard> */}
              </div>
              )
            : ''}
            <OrangeButton
              className='orangeButton'
              $width={width >= WIDTH_MD ? '400px' : ''}
              onClick={() => {
                setModalOpen(true)
                sendDatalayerEvent({
                  section: 'section_3',
                  element_action: 'click button',
                  section_name: 'Como Funciona a API Pix Inter Empresas?',
                  element_name: 'Falar com um especialista',
                })
              }}
            >
              Falar com um especialista
            </OrangeButton>
          </DivPrincipal>
          { width >= WIDTH_XL
            ? (
              <div className='col-xl-7 divCards'>
                <SandCard>
                  <h3>Pix Cob e CobV</h3>
                  <p>Gere cobranças imediatas e com data de vencimento, juros, multas e até descontos.</p>
                </SandCard>
                {/* <SandCard>
                  <h3>Sandbox e SDKs</h3>
                  <p>Ambiente de teste do Inter Empresas. SDKs nas principais linguagens de programação para auxiliar seu desenvolvimento.</p>
                </SandCard> */}
              </div>
            )
            : ''}
        </div>
      </div>
    </ContentHeader>
  )
}

export default ComoFuncionaAApiPix
